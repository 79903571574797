@import "main.css";
/* nav bar et header */

.navbar-nav > .dropdown {
  display: none ;
} 

navbar-collapse ul li span {
font-weight: 400 !important;
}

navbar{
padding: 30px !important;
}

.button_header{
width: 150px !important;
padding: 10px !important;
}

.navbar-nav a {
font-weight: 300 !important;
}

////

.display-3{
width: 200% !important;
}

.servicesList-div > .py-4 {
background-color: #DDE6EC !important;
}

.carousel-inner-homepage .carousel-item-homepage > img{
animation: none !important;
}

/* Cards accueil */

div.redirectsCards-div, div.redirectsCards-div {
margin-bottom: 5rem !important;
}

.redirectsCards-div .row {
padding: 5px !important; 
}

////

.aos-animate img{
margin-left: 18vw !important;
}

.shadow-lg.servicesList-div{
box-shadow: none !important;
}

main{
background-color: #DDE6EC !important;
}

h2 {
text-transform: uppercase !important;
font-weight: 400 !important;
}

h3.display-5 {
font-weight: 500 !important;
margin-top: 1rem !important;
}

.minimum-height{
width: 100% !important;
padding-top: 0 !important;
}

.img-services-list{
display: none !important;
}

.button_header{
display: none !important;
}

.animation {
display: none !important;
}

.text-color-primary > h1 {
font-size: 4vw !important;
margin-left: 17vw !important;
}

.text-color-primary > hr {
width: 70vw !important;
margin-left: 12vw !important;
}

/*
.title-picture-line{
border-top: 4px !important;
}
*/

.card-header{
display: none !important;
}

.carousel{
margin: 20px !important;
}

.bandeau{
display: none !important;
}

.photoTitleText{
display: none !important;
}

div.servicesList-div, div.servicesList-div > div { 
margin-top: 0 !important; 
padding-top: 0 !important;
}

div.photoText-div, div.photoText-div > div {
margin-top: 0 !important; 
padding-top: 0 !important;
}

.lottie-player {
display: none !important;
}

h1{
text-transform: uppercase !important;
color: black !important;
font-size: 3rem !important;
}

span {
text-transform: uppercase !important;
font-weight: 300 !important;
}

h3 {
text-transform : uppercase !important;
font-weight: 300 !important;
color: black !important;
}

.card-header span {
font-weight: 400 !important;
}

.container span {
font-weight: 300 !important;
}

a.dropdown-item:hover{
color: black !important;
}

.button-header{
display: none !important;
}

 /* footer */

.text-color-third{
color: white !important;
}

.bg-thirdColor {
background-color: black !important;
color: white !important;
}

.logo-footer > img { 
filter: brightness(0) invert(1) !important;
}

@media (min-width: 991px) {
div.servicesList-div {
width: 70vw;
}

div.servicesList-div > .row, div.servicesList-div > .row >
div:first-child {
 padding: 0 !important
 }
}


.no-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.before-after-images-column {
  max-height: 50vh
}

.before-after-slider-button {
  height: 100%
}

.image-before-after-container {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.before-image-gallery, .after-image-gallery {
  z-index: 2;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.after-image-gallery {
  z-index: 1;
}

.slider-before-after-images {
  height: 50px;
  width: 50px;
  position: absolute;
  left: 30%; /* Position initiale à 30% */
  top: 50%;
  transform: translate(-50%, -50%);
  border: 3px solid white;
  border-radius: 1000px;
  box-shadow: 0 0 12px rgba(51, 51, 51, .5);
  z-index: 40;
  background: white;
  cursor: pointer;
  touch-action: none;
}

.slider-before-after-images:before {
  content: " ";
  display: block;
  background: white;
  position: absolute;
  z-index: 30;
  width: 5px;
  height: 900px;
  left: 50%;
  margin-left: -2px;
  bottom: 50%;
  margin-bottom: 22px;
}

.slider-before-after-images:after {
  content: " ";
  display: block;
  background: white;
  position: absolute;
  z-index: 30;
  width: 5px;
  height: 900px;
  left: 50%;
  margin-left: -2px;
  top: 50%;
  margin-top: 22px;
}

.navbar-brand { height: 13vh !important; width: 11vw !important; }
.logo_nav { height: 12vh !important }

div.redirectsCards-div, div.redirectsCards-div {  margin-top: 8rem !important;
}


